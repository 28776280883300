import ApiService from '@/services/api.service';

export async function getStates(objectId) {
    const response = await ApiService.get("state/get/"+objectId);
    return response.data;
}
export async function getObjectProgress(objectId){
    const response = await ApiService.get("state/progress/"+objectId);
    console.log("Progress",response);
    return response.data;
}