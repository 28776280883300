<template>
  <v-dialog v-model="show">
    <template #activator="{props}">
      <div v-bind="props" style="width:300px;height:50px;"
           :class="color"
           class="rounded-xl py-4 px-8 d-flex justify-space-evenly align-center" >
        <span class="text-left" style="font-size: 3rem; font-weight: 700" v-text="pendingObjects.length"></span>
        <span class="text-left" v-text="state"></span>
      </div>

    </template>
    <v-card>
      <v-card-text>
        <v-list-item v-for="object of pendingObjects" :key="object.id" :title="objectName(object)" @click="$router.push('/admin/objekt/'+object.id)"></v-list-item>
      </v-card-text>
    </v-card>
  </v-dialog>
  </template>
<script>
import {getObjectName} from "@/services/object.service";

export default {
  name: 'EstateObjectStatisticList',
  data(){
    return {
      show: false,
    }
    },
  props: {
    state:String,
    color:String,
    pendingObjects: {}
  },
  methods:{
    objectName(object){
      if(object) return getObjectName(object);
      else return null;
    },
  }
}
</script>