<template>
  <v-container>
    <v-form @submit.prevent="createSection">
      <v-text-field type="number" label="Nummer" hint="Nach dieser Nummer werden die Sektionen sortiert." v-model="section.id"/>
      <v-text-field type="text" label="Titel" v-model="section.title"/>
      <v-textarea type="text" label="Beschreibung" v-model="section.description"/>
      <v-btn type="submit" block>Sektion erstellen</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import Section from "@/models/section";
import {createSection} from "@/services/courses.service";

export default {
  name: "CreateSection",
  data(){
    return{
      section:new Section()
    }
  },
  methods:{
    createSection() {
      let self=this;
      createSection(this.section).then((response)=>{
        console.log("Create Section",response);
        self.$router.push("/admin/weiterbildung/section")
      });

    }
  }
}
</script>

<style scoped>

</style>