<template>
  <div style="width:100%; position:relative" ref="unboxArea" id=unbox-area>
    <div class="arrow-down"></div>
    <div id=cardList>
      <CaseOpenerCard v-for="(reward,$index) of rewardList" :key="$index" :index="$index" :reward="reward"/>
    </div>
    <v-overlay style="z-index:10" persistent contained class="d-flex justify-center align-center" v-model="showRaffleOverlay">
      <div>
      <v-btn color="accent" :disabled="!rewardList || rewardList.length<=0 || !canRaffle" :loading="loading" @click="openCase">Tagesbonus</v-btn>
      <small v-if="!canRaffle" class="text-center warning">Du kannst nur 1x täglich.</small>
      </div>
    </v-overlay>
  </div>
   <v-dialog v-click-outside="cancel" v-model="showRewardDialog">
    <v-card>
      <v-card-text>
        <div class="d-flex justify-center mx-auto" style="width:200px;min-height: 50px">
          <RewardIconByType :size="100" :type="reward.rewardType"/>
        </div>
        <div class="text-center" style="font-size: 1.4rem" v-html="reward.display"></div>
      </v-card-text>
      <v-card-actions v-if="reward.rewardType==='GIVE_RERAFFLE'">
        <v-btn block @click="cancel">Danke</v-btn>
      </v-card-actions>
      <v-card-actions v-else-if="reward.rewardType==='GIVE_NOTHING'">
        <v-btn block @click="cancel">Schade</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn @click="cancel">Schließen</v-btn>
        <v-spacer/>
        <v-btn @click="claim">Claim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import {claimReward, getDailyRewardList, getRewardObject, startDailyRaffle} from "@/services/reward.service";
import CaseOpenerCard from "@/components/dailyreward/CaseOpenerCard";
import {useUserProgressStore} from "@/stores/progress.module";
import {useRewardStore} from "@/stores/reward.module";
import {useWalletStore} from "@/stores/wallet.module";
import RewardIconByType from "@/components/progress/RewardIconByType";

export default {

  name: "CaseOpener",
  components: {RewardIconByType, CaseOpenerCard},
  setup(){
    const progressStore = useUserProgressStore();
    const rewardStore =useRewardStore();
    const walletStore = useWalletStore();
    return {
      progressStore,
      rewardStore,
      walletStore
    }
  },
  computed:{
    icon() {
      switch (this.reward.type) {
        case 'GIVE_TOKENS':
          return 'GiveTokens.svg'
        case 'GIVE_SLOT':
          return 'Give_Slot.svg'
        case 'GIVE_RERAFFLE':
          return 'GiveReraffle.svg'
        case 'GIVE_NOTHING':
          return 'Give_Nothing.svg'
        default:
          return 'GiveTokens.svg'
      }
    },
    windowWidth(){
      //console.log("Ref",this.$refs.unboxArea.clientWidth);
      //return window.innerWidth;
      return this.$refs.unboxArea.clientWidth
    },
    canRaffle(){
      return this.rewardStore.canRaffle;
    }
  },
  created() {
    this.loadRewardList();
  },
  data() {
    return {
      rewardList:[],
      showRewardDialog:false,
      rewardToClaim:null,
      reward:null,
      loading:false,
      showRaffleOverlay:true
    }
  },
  methods: {
    async loadRewardList() {
      this.rewardList = await getDailyRewardList();
    },
    openCase() {
      if(!this.canRaffle) return null;
      this.showRaffleOverlay=false;
      this.loading=true;
      this.startAnimate();

    },
    async startAnimate() {
      // Get the first element with the 'card' class
      var cardElement = document.querySelector('.card');

      let raffle = await startDailyRaffle(
      {
        min:0,
        max:this.rewardList.length*100,
        windowWidth:this.windowWidth,
        cardWidth:100,
        rewardResponseList:this.rewardList
      });
      let reward = raffle.reward;
      getRewardObject(reward.rewardId).then(response => {
        self.reward=response;
      });
      let animationProperties = {
        marginLeft: raffle.distance + 'px'
      };
      //console.log("RaffleInfo",raffle);
// Generate a random number
      /**
       var rand = this.random(0, this.rewardList.length*100);
       var childNumber = Math.floor(rand / (100)+((this.windowWidth/100)/2));
       //var exactChild= (rand / (100)+((this.windowWidth/100)/2));
       var reward = this.rewardList[childNumber]
       // Define the animation properties
       var animationProperties = {
        marginLeft: -(rand+((this.windowWidth/100)*0,5)) + 'px'
      };
       */
// Define the animation duration
      var animationDuration = 8500;

// Define the timing function
      var timings = ["easeInOutBack", "easeOutExpo", "easeInOutBounce", "easeOutQuad", "swing", "easeOutElastic", "easeInOutElastic"];
      var timing = timings[this.random(0, timings.length)];
      let self = this;
// Define the callback function
      var callback = function () {
        self.rewardToClaim = reward;


        self.loading = false;
        self.rewardStore.getMyRewards();
        self.walletStore.getMyWallet();
        self.progressStore.removeNewReward(self.reward);
        self.showRewardDialog = true;
        setTimeout(()=>{
          //self.showRewardDialog = true;
          self.showRaffleOverlay=true;
        },1000)
      };

// Perform the animation using the animate function
      this.animate(cardElement, animationProperties, animationDuration, timing, callback);


    },
    animate(element, properties, duration, timing, callback) {
      var start = performance.now();
      var originalValues = {};

      for (var property in properties) {
        if (Object.prototype.hasOwnProperty.call(properties, property)) {
          originalValues[property] = element.style[property];
        }
      }

      function animateStep(timestamp) {
        var time = timestamp - start;

        if (time >= duration) {
          time = duration;
        } else {
          requestAnimationFrame(animateStep);
        }

        var progress = time / duration;

        for (var property in properties) {
          if (Object.prototype.hasOwnProperty.call(properties, property)) {
            var startValue = parseFloat(originalValues[property]) || 0;
            var endValue = parseFloat(properties[property]);
            var currentValue = startValue + (endValue - startValue) * progress;
            element.style[property] = currentValue + 'px';
          }
        }

        if (time === duration && typeof callback === 'function') {
          callback();
        }
      }

      requestAnimationFrame(animateStep);
    },
    random(min, max){
      return Math.floor((Math.random()*(max - min))+min);
    },
    cancel(){
      this.showRewardDialog=false;
      this.rewardStore.canUserRaffle();
    },
    claim(){
      let self=this;
      claimReward(this.rewardToClaim).then(() =>{
        //TODO Display Popup wih Hey you claimed the reward
        self.rewardStore.getMyRewards();
        self.walletStore.getMyWallet();
        self.showRewardDialog=false;
        self.rewardStore.canUserRaffle();
      });
    }
  }
}
</script>

<style scoped>
#cardList {
  height: 100px;
  width: 100%;
  position: relative;
  border: 2px solid black;
  overflow: hidden;
  white-space: nowrap;
}


.arrow-down {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid white;
  z-index: 2;
}

#dialog-msg > img {
  width: 150px;
  height: 150px;
}

#dialog-msg {
  text-align: center;
}
</style>