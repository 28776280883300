<template>
  <div ref="xpBarSmall" class="animate__animated d-flex align-center">
    <div class="flex-grow-1 mr-1">
<v-progress-linear rounded color="accent" :model-value="(pointsInCurrentLevel/pointsToNextLevel)*100" height="14px">
  <template v-slot:default>
    <small style="font-size: 0.5rem;" class="font-weight-bold">{{pointsInCurrentLevel}} / {{pointsToNextLevel}}</small>
  </template>

</v-progress-linear>
      <ConfettiExplosion v-if="confetti"/>
    </div>
    <span style="font-size:0.7rem">Level {{currentLevel}}</span>
  </div>
</template>

<script>
import {useUserProgressStore} from "@/stores/progress.module";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {calculateXPNeededForNextLevel} from "@/services/progress.service";
import ConfettiExplosion from "vue-confetti-explosion";
import {nextTick} from "vue";

export default {
  name: "ProgressBarSmall",
  components:{
    ConfettiExplosion
  },
  data(){
    return{
    confetti:false
    }
  },
  setup () {
    const authUser = useAuthUserStore();
    const progressStore = useUserProgressStore();
    if(authUser.status.loggedIn) progressStore.getProgress();
    return {
      // you can also access the whole store in your component by returning it
      progressStore,
      authUser
    }
  },
  watch:{
    progress(newValue,oldValue){
      if(newValue.totalPoints>oldValue.totalPoints) {
        this.$refs.xpBarSmall.classList.add("animate__rubberBand");

        setTimeout(() => {
          this.$refs.xpBarSmall.classList.remove("animate__rubberBand");

        }, 1500)
        setTimeout(() => {
          this.explode();

        }, 500)
      }
    }
  },
  methods:{
    async explode(){

        this.confetti = false;
        await nextTick();
        this.confetti = true;

    }
  },
  computed:{
    progress(){
      return this.progressStore.progress
    },
    currentLevel(){
      return this.progressStore.progress.level;
    },
    pointsInCurrentLevel(){
      return this.progressStore.progress.pointsInCurrentLevel;
    },
    pointsToNextLevel(){
      return calculateXPNeededForNextLevel(this.progressStore.progress.level);
    }
  }
}
</script>

<style scoped>

</style>