<template>
  <v-container class="d-flex flex-wrap align-content-space-between">
    <div style="min-height:20vh;width:100%" class="py-10">
      <div style="font-size: 3rem;width:100%" class="d-flex text-accent align-center justify-center">

       <span>
        <v-badge floating >
          <template #badge>
          <CoinFormulaDisplay v-if="sale" :marktwert="sale.value" :provision="sale.provision" :tippgeber-provision="sale.tipsterProvision"/>
          </template>
          <span v-html="coins"></span>
        </v-badge>
      </span>

      </div>
      <div>Coins Gewinn</div>
    </div>

    <v-list v-if="requirements && states" bg-color="transparent" width="100%">
      <OwnerConfirmationStep :disabled="requirements.verificationSuccess.value" :show="showConsentForm" @toggleDialog="toggleOwnerConfirmationDialog" @update="updateConsentForm"  :consent-form="consent"/>
      <ObjectDataStep :state="states.objectDataState" :object="immobilie" :show="showObjectData" @toggleDialog="toggleObjectDataDialog" :requirements="[requirements.consentCheckboxIsTrue,{value:!requirements.verificationRequested.value}]"/>
      <VerificationStep v-if="immobilie" :object="immobilie" @toggleDialog="toggleVerificationDialog" :show="showVerification" :state="states.verificationState"  :requirements="[requirements.consentContractIsPresent,requirements.allObjectDataIsPresent]"/>
      <v-list-subheader v-if="requirements.verificationSuccess.value && requirements.allObjectDataIsPresent.value && requirements.consentContractIsPresent.value" color="green">Danke! Dein Part ist geschafft.</v-list-subheader>
      <v-divider></v-divider>
      <ContractStep :show="showContract" @toggleDialog="toggleContractDialog" :object-id="immobilie.id" :state="states.contractState" :requirements="[requirements.consentContractIsPresent,requirements.allObjectDataIsPresent,requirements.verificationSuccess]"/>
      <MarketingStep :sale="sale" @toggleDialog="toggleMarketingDialog" :show="showMarketing" :state="states.contractState" :requirements="[requirements.consentContractIsPresent,requirements.allObjectDataIsPresent,requirements.verificationSuccess]"/>
      <NotarStep :state="states.notaryAppointmentState" :requirements="[requirements.consentContractIsPresent,requirements.allObjectDataIsPresent,requirements.verificationSuccess,requirements.contractSigned]"/>
    <PaymentReceivedStep :state="states.paymentState" :requirements="[requirements.consentContractIsPresent,requirements.allObjectDataIsPresent,requirements.verificationSuccess,requirements.contractSigned,requirements.notaryAppointmentSuccess]"/>
      <CollectCoinsStep :state="states.coinsCollectedState" :object-id="immobilie.id" :requirements="[requirements.consentContractIsPresent,requirements.allObjectDataIsPresent,requirements.verificationSuccess,requirements.contractSigned,requirements.notaryAppointmentSuccess,requirements.paymentReceived]"/>
    </v-list>

  </v-container>
  <ObjectProgressFooter v-if="immobilie" :object="immobilie" title="Gesamtfortschritt"/>
</template>

<script>
import VerificationStep from "@/components/objects/progress/VerificationStep";
import ObjectDataStep from "@/components/objects/progress/ObjectDataStep";
import ObjectProgressFooter from "@/components/objects/progress/ObjectProgressFooter";
import ContractStep from "@/components/objects/progress/ContractStep";
import NotarStep from "@/components/objects/progress/NotarStep";
import Status from "@/models/status";
import OwnerConfirmationStep from "@/components/objects/progress/OwnerConfirmationStep";
import PaymentReceivedStep from "@/components/objects/progress/PaymentReceivedStep";
import CollectCoinsStep from "@/components/objects/progress/CollectCoinsStep";
import {getConsentForm, getEstateObject, getSale, saveConsentForm} from "@/services/object.service";
import consentForm from "@/models/consentForm";
import {useObjectStore} from "@/stores/objects.module";
import MarketingStep from "@/components/objects/progress/MarketingStep";
import CoinFormulaDisplay from "@/components/objects/progress/CoinFormulaDisplay";
export default {
  name: "ObjectView",
  setup(){
    const objectState = useObjectStore();

    return{
      objectState
    }
  },
  components:{
    CoinFormulaDisplay,
    MarketingStep,
    CollectCoinsStep,
    PaymentReceivedStep,
    OwnerConfirmationStep,
    NotarStep,
    ContractStep,
    ObjectProgressFooter,
    ObjectDataStep,
    VerificationStep

  },
  props:{
    objectId:String
  },
  data(){
    return{
      showContract:false,
      showMarketing:false,
      showConsentForm:false,
      showObjectData:false,
      showVerification:false,
      immobilie:null,
      sale:null,
      consent: new consentForm(),
    }
  },
  created() {
    console.log("Id",this.objectId)
    this.getObject(this.objectId);
    this.getConsentForm(this.objectId);
    this.getSaleObject(this.objectId);
    this.objectState.updateRequirements(this.objectId);
    this.objectState.updateStates(this.objectId);

      window.setInterval(() => {
        this.objectState.updateRequirements(this.objectId);
        this.objectState.updateStates(this.objectId);
      }, 60000)

  },

  methods:{
    toggleVerificationDialog(value){
      this.showVerification=value;
    },
    toggleContractDialog(value){
      this.showContract=value;
    },
    toggleMarketingDialog(value){
      this.showMarketing=value;
    },
    toggleOwnerConfirmationDialog(value){
      this.showConsentForm=value;
    },
    toggleObjectDataDialog(value){
      this.showObjectData=value;
    },
    async updateConsentForm(value) {
      this.consent = value;
      this.consent = await saveConsentForm(this.consent);
      this.objectState.updateRequirements(this.objectId);
      this.objectState.updateStates(this.objectId);

    },
    async getSaleObject(id){
      if (id) this.sale = await getSale(id);
      else this.$router.push("/");
    },
    async getObject(id) {
      if (id) this.immobilie = await getEstateObject(id);
      else this.$router.push("/");
    },
    async getConsentForm(id) {
      if (id) this.consent = await getConsentForm(id);
      else this.$router.push("/");
    },
  },
  computed:{
    requirements(){
      return this.objectState.requirements;
    },
    states(){
      return this.objectState.states;
    },
    verificationStatus(){
      return new Status({success:this.requirements.verificationSuccess,loading:this.requirements.verificationRequested})
    },
    contractStatus(){
      return new Status({success: this.requirements.contractSigned,waiting: this.requirements.contractIsPresent});
    },

    objectDataStatus(){
      return new Status({success:this.requirements.allObjectDataIsPresent})
    },
    formula(){
      if(!this.sale) return 0;
      let value=this.sale.value;
      if(this.sale.actualValue) value=this.sale.actualValue;
      return (value*(this.sale.provision/100))*(this.sale.tipsterProvision/100);
    },
    coins(){
      if(!this.sale) return null;
      let provision= this.sale.provision;
      let tipsterProvision= this.sale.tipsterProvision;
      let sale=0;

      if(this.sale.actualValue>0) sale=this.sale.actualValue;
      else sale=this.sale.value;
      //Markwert*Innenprov*50%;
      let result=(sale*(provision/100))*(tipsterProvision/100);
      return new Intl.NumberFormat('en-DE').format(Math.ceil(result));
    },
  }
}
</script>

<style scoped>

</style>