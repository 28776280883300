import ApiService from "@/services/api.service"
export async function getRanking(page) {
    const response = await ApiService.get("ranking/get/" + page);
    return response.data;
}
export async function getRankingHistory() {
    const response = await ApiService.get("ranking/history/simple");
    return response.data;
}
export async function getUserRank() {
    const response = await ApiService.get("ranking/self");
    return response.data;
}
export async function getUserRankForList() {
    const response = await ApiService.get("ranking/list/self");
    return response.data;
}