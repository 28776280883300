<template>
  <v-dialog fullscreen v-model="show">

    <template #activator="{props}">
      <label>{{label}}</label>
      <v-text-field placeholder="Datum auswählen..." readonly v-bind="props" :value="formattedDate"/>
    </template>
    <v-card>
      <v-date-picker class="align-self-center" @click:cancel="show=false" @click:save="show=false" elevation="0"
                     v-model="selectedDate"/>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DatePicker',
  props: {
    date:String,
    label:String
  },
  computed:{
    formattedDate(){
      let moment = require('moment');
      moment.locale('de');
      return moment(this.selectedDate).format("LL")
    },
    selectedDate:{
      get(){
        return this.date;
      },set(value){
        this.$emit("update",value);
      }
    }
  },
  data(){
    return{
      show:false
    }
  }
}
</script>
