<template>
  <v-sheet style="position: relative;z-index:1;overflow: hidden;border-radius: 20px" width="100%" height="150" class=" pa-5" color="blue">
  <div style="z-index:1;position: relative" class="d-flex justify-space-between ">
    <span class=" d-flex align-center"><CoinsIcon :size="20" class="align-self-center" start/> Estate Coins</span> <span></span>
    <v-tooltip style="z-index:1;position: relative" location="bottom" text="Vergleichwert immer der 1. des Monats">
      <template #activator="{props}">
    <v-chip :color="coinsCompareColor"
            v-bind="props"
            label
            text-color="white"><span v-if="coinsCompare>0">+</span> {{coinsCompare}}% </v-chip>

      </template>

    </v-tooltip>

  </div>
  <div style="z-index:1" class="d-flex justify-start align-center">
    <span style="font-size: 2rem;">{{coins}}</span>
  </div>
  <div style="position: relative;z-index:1" class="d-flex justify-start align-center">
    <v-btn variant="tonal" @click="$router.push('/wallet')" size="small" color="accent" style="">Zur Wallet</v-btn>
  </div>
    <div class="overlay rounded"></div>
    <div class="overlay-lineChart">
      <trend
          style="height:100%;width:105%"
          :data="graphCoins"
          :gradient="['#6fa8dc', '#42b983', '#2c3e50']"
          :padding="4.8"
          :radius="12"
          :stroke-width="5"
          auto-draw
          smooth>
      >
      </trend>
    </div>
</v-sheet>
</template>

<script>
import CoinsIcon from "@/icons/CoinsIcon";
import {useWalletStore} from "@/stores/wallet.module";
export default {
  name: "YourPerformance",
  components: {CoinsIcon},
  setup(){
    const walletStore= useWalletStore();
    walletStore.getMyWallet();
    walletStore.getWalletHistory();
    walletStore.getCoinsCompareValue();
    return{
      walletStore
    }
  },
  computed:{
    wallet(){
      return this.walletStore.wallet;
    },
    coins(){
      return new Intl.NumberFormat('en-DE').format(this.wallet.coins);
    },
    coinsCompareColor(){
      if(this.coinsCompare>0) return 'success';
      else if(this.coinsCompare<0) return 'error';
      else return 'grey';
    },
    coinsCompare(){
      return this.walletStore.historyCompareCoinsValue;
    },
    graphCoins(){
      let result=this.history.map(a => a.coins);
      if(result.length<10){
        let fill = [0,0,0,0,0,0,0,0,0,0];
      result = fill.concat(result);

      }

      return result;
    },
    history(){
      return this.walletStore.history;
    }
  }
}
</script>

<style scoped>

.overlay{
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  opacity:0.05;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
.overlay-lineChart{
  position:absolute;
  bottom:0;
  left:0;
  transform: translateX(-2.5%);
  width:100%;
  height:100%;
  z-index:-1;
  opacity: 0.5;
}
</style>