<template>
  <div class="my-10">
    <!--<v-checkbox label="Kunde hat gezahlt."/>-->
    <v-btn :loading="loading" v-if="!sale.paymentReceived" @click="saveSale(true)" block color="accent">Immobilie für User abschließen*</v-btn>
    <small v-if="!sale.paymentReceived">*User kann damit seine Coins einsammeln</small>
    <span v-else>User kann nun seine Coins abholen</span>
  </div>
</template>

<script>
import {changePaymentStatus} from "@/services/admin.service";

export default {
  name: "EditVerkauf",
  props:{
    sale:Object
  },
  data(){
    return{
      loading:false
    }
  },
  methods:{
    async saveSale(value){
      this.loading=true;
      let sale= await changePaymentStatus(this.sale.objectId,value)
      this.$emit('update',sale);
      this.loading=false;

      location.href = '/admin/objekt/'+this.sale.objectId;
    },
  }
}
</script>

<style scoped>

</style>