<template>
<div>
  <OwnerInformation :owner="object.objectOwner"/>
  <p class="my-2">Interesse an {{object.sellType}}</p>
  <p class="my-2">Objektart {{object.objectType.replace('TYPE_','')}}</p>
  <p class="my-2">Wohnfläche {{object.livingArea}}m²</p>
  <p class="my-2">Grundfläche {{object.landArea}}m²</p>
  <div class="pt-2" style="width:100%;height:200px;">
    <MapDisplayOnly v-if="object.address.lat && object.address.lng" :marker-location="[object.address.lat,object.address.lng]"/>
  </div>
  <div>
    <ImageGallery :images="object.images"/>
  </div>
  <UserDisplaySmall :user-id="object.createdBy"/>
</div>
</template>

<script>
import OwnerInformation from "@/components/admin/OwnerInformation";
import MapDisplayOnly from "@/components/map/MapDisplayOnly";
import ImageGallery from "@/components/ImageGallery";
import UserDisplaySmall from "@/components/admin/object/UserDisplaySmall";
export default {
  name: "ObjectInfo",
  components: {UserDisplaySmall, ImageGallery, MapDisplayOnly, OwnerInformation},
  props:{
    object:Object
  }
}
</script>

<style scoped>

</style>