<template>
  <div id="gluecksrad" style="max-width: 100%" class="d-flex align-center justify-center">
  <div v-if="!canRaffle" class="d-flex justify-center">
    <CountdownTimer v-if="time" :expiry-timestamp="time"/>
  </div>
  <CaseOpener v-else />
  </div>
</template>

<script>
import CountdownTimer from "@/components/countdown/CountdownTimer";
import {lastUserRaffle} from "@/services/reward.service";
import {useRewardStore} from "@/stores/reward.module";
import CaseOpener from "@/components/dailyreward/CaseOpener";
export default {
  name: "DailyRewardDashboard",
  setup(){
    const rewardStore= useRewardStore();
    rewardStore.canUserRaffle();
    return{
      rewardStore
    }
  },
  components:{
    CaseOpener,
    CountdownTimer

  },
  data(){
    return{
      time:null
    }
  },
  created() {
  this.getLastRaffle();
    },
  methods:{
    async getLastRaffle() {

      const time = new Date(await lastUserRaffle());
      time.setHours(time.getHours() + 24);
      this.time =  time;
    }
},
  watch:{
    canRaffle(){
      this.getLastRaffle();
    }
  },
  computed: {
    canRaffle(){
      return this.rewardStore.canRaffle;
    }
  }
}
</script>

<style scoped>

</style>