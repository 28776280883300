export default class NotificationElephant{
    id;
    message;
    createdAt;
    userId;
    fromUserId;
    type;
    state;
    link;
    color;
    title;
    image;
    icon;
    constructor({title,message=null,userId=null,fromUserId=null,type='TEXT',link=null,color="NEUTRAL",image=null,icon=null}) {
        this.title=title;
        this.color = color;
        this.id = null;
        this.userId=userId;
        this.message = message;
        this.fromUserId = fromUserId;
        this.type = type;
        this.state = 'NEW';
        this.link = link;
        this.image=image;
        this.icon=icon;
    }
}