<template>
  <div class="card">
    <div class="card__front card__part">
      <div class="d-flex justify-start">
        <img class="card__front-square card__square" src="https://i.ibb.co/G9pDnYJ/chip.png">

      </div>
      <img class="card__front-logo card__logo" :src="require('@/assets/app_icon.svg')">
      <p class="card_numer">{{ iban }}</p>
      <div style="width:100%" class="d-flex justify-end">
        <div class="card__space-75" style="width:50%">
          <span class="card__label">Kartenbesitzer</span>
          <p class="card__info" v-text="bankDetails.accountHolder"></p>

        </div>
      </div>
    </div>

    <div class="card__back card__part">
      <div class="card__black-line"></div>
      <div class="card__back-content">
        <div class="card__secret">

        </div>

      </div>
      <div class="d-flex">
        <div class="card__space-25" style="width: 70%;align-self: end;line-height: 18px">
          <p v-text="bankDetails.bankName"></p>
        </div>
        <div class="card__space-25">
          <span class="card__label">BIC</span>
          <p class="card__info" v-text="bankDetails.bic"></p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'CardView',
  props: {
    bankDetails: {},
  },
  computed:{
    iban(){
      return this.bankDetails?.iban?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    }
  }
}
</script>
<style scoped>
.card {
  width: 320px;
  height: 190px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;

}

.card__part {
  box-shadow: 1px 1px #aaa3a3;
  top: 0;
  position: absolute;
  z-index: 1000;
  left: 0;
  display: inline-block;
  width: 320px;
  height: 190px;
  background-image: url('https://image.ibb.co/bVnMrc/g3095.png'), linear-gradient(
      310deg,
      hsl(35deg 46% 63%) 0%,
      hsl(21deg 44% 63%) 23%,
      hsl(6deg 35% 61%) 40%,
      hsl(347deg 25% 56%) 55%,
      hsl(326deg 17% 49%) 68%,
      hsl(286deg 13% 43%) 80%,
      hsl(243deg 14% 39%) 91%,
      hsl(216deg 23% 31%) 100%
  ); /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.card__front {
  padding: 18px;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
}

.card__back {
  padding: 18px 0;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
}

.card__black-line {
  margin-top: 5px;
  height: 38px;
  background-color: #303030;
}

.card__logo {
  height: 35px;
}

.card__front-logo {
  position: absolute;
  top: 18px;
  right: 18px;
}

.card__square {
  border-radius: 5px;
  height: 40px;
}

.card_numer {
  display: block;
  width: 100%;
  word-spacing: 4px;
  font-size: 20px;
  letter-spacing: 2px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.card__space-75 {
  width: 40%;
  float: right;
}

.card__space-25 {
  width: 40%;
  float: left;
}

.card__label {
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 1px;
}

.card__info {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.card__back-content {
  padding: 15px 15px 0;
  height: 60px;
}

.card__secret {
  padding: 5px 12px;
  background-color: transparent;
  position: relative;
}

.card__secret:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  height: 35px;
  width: 100%;
  border-radius: 4px;
  background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
}

.card:hover .card__front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);

}

.card:hover .card__back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
}
</style>