<template>
  <div style="min-height: 75px">
    <v-img :width="size" style="margin-left:10px;margin-top:-10px" :src="require('@/assets/icons/'+icon)"/>
  </div>
</template>

<script>
export default {
  name: "RewardIconByType",
  props:{
    type:String,
    size:{
      type:Number,
      default:50
    }
  },
  computed:{
    icon(){
      switch(this.type){
        case 'GIVE_COINS':
          return 'GiveTokens.svg';
        case 'GIVE_TOKENS':
          return 'GiveTokens.svg';
        case 'GIVE_SLOT':
          return 'Give_Slot.svg';
        case 'GIVE_EDUCATION':
          return 'Plus.svg';
        case 'GIVE_RERAFFLE':
          return 'GiveReraffle.svg';
        case 'GIVE_NOTHING':
          return 'Give_Nothing.svg';
        default:
          return 'Plus.svg';
      }
    }
  }
}
</script>

<style scoped>

</style>