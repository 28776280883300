<template>
<v-list-item class="bg-secondary my-2" rounded elevation="2" lines="three" :title="address.street">
  <template #prepend>
    <v-list-item-media>
      <v-avatar class="mr-5 rounded" size="100px">
    <MapDisplayOnly v-if="address.lat && address.lng" :marker-location="[address.lat, address.lng]"/>
        <div v-else>Addresse</div>
      </v-avatar>
    </v-list-item-media>
  </template>
  <template #subtitle>
    <div>{{address.postcode}} {{address.city}}</div>
    <div>{{address.country}}</div>
  </template>
  <template #append>
    <v-list-item-media>
      <v-btn :disabled="disabledP" variant="plain" @click="edit=!edit" icon><v-icon icon="mdi-pencil"></v-icon></v-btn>
      <EditAddressView @updateAddress="updateAddress" @toggleDialog="edit=!edit" :show="edit" :address="address"/>
    </v-list-item-media>
  </template>
</v-list-item>
</template>

<script>
import MapDisplayOnly from "@/components/map/MapDisplayOnly";
import EditAddressView from "@/views/EditAddressView";
export default {
  name: "AddressSmallVariant",
  components: {EditAddressView, MapDisplayOnly},
  data(){
    return{
      edit:false

    }
    },
  computed:{
    addressReactive:{
      get(){
        return this.address;
      },set(value){
        this.$emit("update",value);
      }
    }
  },
  methods:{
    updateAddress(value){
      this.addressReactive=value;
    }
  },
  props:{
    disabledP:Boolean,
    address:Object
  }
}
</script>

<style scoped>

</style>