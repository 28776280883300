export default class File{
    constructor(filename=null,contentType=null,size=0,file=null){
        this.id=null;
        this.filename=filename;
        this.contentType=contentType;
        this.size=size;
        this.uploadVonId=null;
        this.file=file;
        this.created_at=null;
    }

}