export default class Section{
    id;
    title;
    description;
    courses;
    constructor(id,title,description,courses=[]){
        this.id = id;
        this.title = title;
        this.description = description;
        this.courses = courses;


    }
}