// Pinia Store
import { defineStore } from 'pinia'

const state = () => ({
        showBottomSettings:false,
        showIdentification:false
});
export const useAppStore = defineStore('appStore',{
    state: state,



// getters
    getters: {

    },

// actions
    actions:{
        toggleBottomSettings(){
            this.showBottomSettings=!this.showBottomSettings;
        },
        toggleIdentificationDialog(value){
            if(value) this.showIdentification=value;
            else this.showIdentification=!this.showIdentification;
        },
        addCoins(){

        },
        addTokens(){

        }

    }
});