<template>
  <v-footer order="3" color="secondary" app>
    <v-sheet class="px-2 py-5" color="transparent" width="100%">
      <v-list-item class="px-0"  align="left" >
        <template #title>
          {{title}}
        </template>
        <template #subtitle>
          {{objectName}}
        </template>

        <template #append>
          <v-card class="ml-5" width="70px" height="50px">
          <v-carousel height="100%" cycle :show-arrows="false" hide-delimiters>
            <v-carousel-item v-for="image of object.images" :key="image.id"
                :src="'data:image/webp;base64,'+image.file"
                cover
            ></v-carousel-item>

          </v-carousel>
          </v-card>
        </template>
      </v-list-item>
      <v-progress-linear height="10px" color="accent" striped v-model="progress" rounded class="my-2"></v-progress-linear>
      <div style="line-height: 1em;" class="text-left">
        <small>{{object.sellType}}</small><br/>
        <small>{{object.livingArea}}m² Wohnfläche</small>
      </div>
    </v-sheet>
  </v-footer>
</template>

<script>
import {getObjectName} from "@/services/object.service";
import {getObjectProgress} from "@/services/state.service";

export default {
  //TODO get progress from backend -> get by adding all requirements together
  name: "ObjectProgressFooter",
  props:{
    title:String,
    subtitle:String,
    error:Boolean,
    object:Object
  },
  data(){
    return{
    progress:0
    }
  },
  created() {
    this.getProgress();
  },
  methods:{
    async getProgress() {
      this.progress = await getObjectProgress(this.object.id);
    }
  },
  computed:{
    objectName(){

      if(this.object)return getObjectName(this.object);
      else return null;
    }
  }
}
</script>

<style scoped>

</style>