import ApiService from "@/services/api.service";


export async function requestVerification(objectId){
    const response = await ApiService.get("object/verification/request/"+objectId);
    return response.data;
}
export async function getVerification(objectId){
    const response = await ApiService.get("object/verification/get/"+objectId);
    return response.data;
}