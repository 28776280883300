// Pinia Store
import { defineStore } from 'pinia';
import ApiService from '@/services/api.service';

const state = () => ({
        pendingVerifications:[],
        pendingEstateObjects:[],
        cancelledEstateObjects:[],
        successEstateObjects:[],
        courses:[
        ]
});
export const useAdminStore = defineStore('adminStore',{
    state: state,



// getters
    getters: {

    },

// actions
    actions:{
        getPendingVerifications(){
            let self=this;
            ApiService.get("object/verification/admin/all/pending").then(response =>{
                self.pendingVerifications=response.data;
            })
        },
        getPendingEstateObjects(){
            let self=this;
            ApiService.get("object/all/pending").then(response => {
                self.pendingEstateObjects=response.data;
            })
        },
        getCancelledEstateObjects(){
            let self=this;
            ApiService.get("object/all/cancelled").then(response => {
                self.cancelledEstateObjects=response.data;
            })
        },
        getSuccessEstateObjects(){
            let self=this;
            ApiService.get("object/all/success").then(response => {
                self.successEstateObjects=response.data;
            })
        },
    }
});