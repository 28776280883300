<template>
<v-container v-if="statisticObject">
  <v-row style="height:80vh;">
    <v-col cols="8">
      <v-row style="height:50%;">
        <v-col style="background-color: red;" cols="12">
          <h2>Estate Objekte</h2>
          <EstateObjectStatistic :canceled-objects="canceledObjects" :pending-objects="pendingObjects"
                        :sold-objects="soldObjects"/>

        </v-col>
      </v-row>
      <v-row style="height:50%">
        <v-col style="background-color: blue;" cols="12">
            <PayoutItem v-for="payout of payouts" :key="payout.id" :payout="payout"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col style="background-color: orange;" cols="4">
      <div class="">
        <h2>Estate Users</h2>
        <div style="width:100px;height:100px;font-size: 3rem; font-weight: 700" class="rounded-xl bg-secondary mx-auto d-flex justify-center align-center" v-text="statisticObject.allUsers.length"></div>
        <v-list max-height="400px">
          <v-list-item align="left"  v-for="user of statisticObject.allUsers" :key="user.user.id" :title="user.user.firstName+' '+user.user.lastName">
            <template #subtitle>
             <span><span>Level</span> <span v-text="user.progress.level"></span></span> <span v-text="user.user.email"></span>
            </template>
          </v-list-item>
        </v-list>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import {getAdminStatistics} from "@/services/statistic.service";
import PayoutItem from "@/components/payout/PayoutItem";
import EstateObjectStatistic from "@/components/admin/statistics/EstateObjectStatistic";

export default {
  name: "StatisticDashboard",
  components: {EstateObjectStatistic, PayoutItem},
  data(){
    return{
      statisticObject:null
    }
  },
  created() {
    this.getStatistics();
  },
  computed:{
    pendingObjects(){
      return this.statisticObject.allEstateObjects.filter(x=> x.state==='PENDING');
    },soldObjects(){
      return this.statisticObject.allEstateObjects.filter(x=> x.state==='SUCCESS');
    },canceledObjects(){
      return this.statisticObject.allEstateObjects.filter(x=> x.state==='CANCELED');
    },
    payouts() {
      return this.statisticObject.allPayouts;
    }
  },
  methods:{
    async getStatistics() {
      this.statisticObject = await getAdminStatistics();
    }
  }
}
</script>

