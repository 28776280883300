import ApiService from "@/services/api.service";

export async function getMyRewards() {
    const response = await ApiService.get("reward/my");
    return response.data;

}
export async function claimReward(reward){
    const response = await ApiService.post("reward/claim",reward);
    return response.data;

}

export async function getRewardObject(rewardId) {
    const response = await ApiService.get("reward/object/" + rewardId);
    return response.data;
}

export async function getRewardByLevel(level){
    const response = await ApiService.get("reward/object/level/"+level);
    return response.data;
}

export async function getDailyRewardList(){
    const response = await ApiService.get("reward/daily/list");
    return response.data;
}
export async function startDailyRaffle(request){
    const response = await ApiService.post("reward/daily/raffle",request);
    return response.data;
}
export async function canUserRaffle(){
    const response = await ApiService.get("reward/daily/canraffle");
    return response?.data;
}

export async function lastUserRaffle(){
    const response = await ApiService.get("reward/daily/lastRaffle");
    return response.data;
}
