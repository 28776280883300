<template>

    <v-container class="d-flex justify-space-between align-center"  v-if="currentUser">
      <div @click="$router.push('/profil')" class="text-left"><small class="text-disabled">{{ greeting }}</small> <br/> <span class="text-h5">{{currentUser.firstName}} {{currentUser.lastName}}</span></div>

      <div class="d-flex align-center">
        <NotificationBell ref="notifications"/>
        <CurrentUserAvatar @click="$router.push('/profil')" :size="50" style="border: 2px solid white"></CurrentUserAvatar>

      </div>
    </v-container>

</template>

<script>
import {useAppStore} from "@/stores/app.module";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {useRewardStore} from "@/stores/reward.module";
import CurrentUserAvatar from "@/components/CurrentUserAvatar";
import NotificationBell from "@/components/notification/NotificationBell";

export default {
  name: "GreetingUser",
  components: {NotificationBell, CurrentUserAvatar},
  setup(){
    const appStore = useAppStore();
    const authStore = useAuthUserStore();
    const rewardStore = useRewardStore();
    rewardStore.getMyRewards();

    return {
      appStore,
      authStore,
      rewardStore
    }
  },
  computed:{
    currentUser(){
      return this.authStore.user;
    },
    greeting(){
      let moment = require('moment');

      let time = moment().format("HH");
      if(time>=4 && time<12 ) return "Guten Morgen, "
      else if(time>=12 && time<13) return "Mahlzeit, "
      else if(time>=18 && time<23) return "Guten Abend, "
      else return "Guten Tag, "
    }
  }
}
</script>

