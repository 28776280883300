import ApiService from '@/services/api.service';
export async function acceptVerification(objectId) {
    const response = await ApiService.get("object/verification/accept/" + objectId);
    return response.data;
}
export async function declineVerification(objectId,message) {
    const response = await ApiService.post("object/verification/decline/" + objectId,{string:message});
    return response.data;
}

export async function getContract(objectId){
    const response = await ApiService.get("object/contract/get/"+objectId);
    return response.data;
}
export async function createContract(objectId){
    const response = await ApiService.get("object/contract/create/"+objectId);
    return response.data;
}
export async function editContract(contract){
    const response = await ApiService.post("object/contract/edit",contract);
    return response.data;
}
export async function createNotaryAppointment(objectId){
    const response = await ApiService.get("object/notaryappointment/create/"+objectId);
    return response.data;
}
export async function editNotaryAppointment(object){
    const response = await ApiService.post("object/notaryappointment/edit",object);
    return response.data;
}
export async function getNotaryAppointment(objectId){
    const response = await ApiService.get("object/notaryappointment/get/"+objectId);
    return response.data;
}
export async function changePaymentStatus(objectId,value){
    const response = await ApiService.post("object/sale/"+objectId+"/set/payed",{value:value});
    return response.data;
}
export async function updateValue(objectId,value){
    console.log("Objekt Id",objectId)
    const response = await ApiService.post("object/sale/"+objectId+"/set/value",{value:value});
    return response.data;
}
export async function updateActualValue(objectId,value){
    const response = await ApiService.post("object/sale/"+objectId+"/set/actualValue",{value:value});
    return response.data;
}
export async function updateProvision(objectId,value){
    const response = await ApiService.post("object/sale/"+objectId+"/set/provision",{value:value});
    return response.data;
}
export async function updateTipsterProvision(objectId,value){
    const response = await ApiService.post("object/sale/"+objectId+"/set/tipsterProvision",{value:value});
    return response.data;
}

export async function getAllPendingPayouts(){
    const response = await ApiService.get("payout/all/pending");
    return response.data;
}