export default class Status{
    success;
    error;
    loading;
    waiting;
    sleep;
    activeUserInteraction;
    constructor({success,error,loading,waiting,sleep,activeUserInteraction}) {
        this.error = error;
        this.loading = loading;
        this.waiting = waiting;
        this.sleep = sleep;
        this.activeUserInteraction = activeUserInteraction;
        this.success = success;

    }
}