import ApiService from "@/services/api.service";
import {useNotificationStore} from "@/stores/notification.module";
import NotificationElephant from "@/models/notification";
import {useAuthUserStore} from "@/stores/modules/auth.module";

export async function confirmToken(token){
    const response = await ApiService.get("token/confirm/"+token);
    return response.data;
}
export async function sendToken(){
    const response = await ApiService.get("token/authenticated/confirm/resend");
    const notificationStore = useNotificationStore();
    if(response.data){
        notificationStore.push(new NotificationElephant({title:"Bestätigungsmail gesendet.",message:null,color:'SUCCESS'}))
    }else{
        notificationStore.push(new NotificationElephant({title:"Account wurde bestätigt.",message:null,color:'SUCCESS'}))
        const authStore = useAuthUserStore();
        authStore.verifyEmail();
    }
    return response.data;
}