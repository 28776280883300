<template>
  <v-row class="pa-2 pt-4">
    <v-col
        v-for="image of images"
        :key="image.id"
        class="d-flex child-flex pa-1"
        cols="6"
    >
      <v-img
          :src="'data:image/webp;base64,'+image.file"
          aspect-ratio="1"
          cover
          class="bg-grey-lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                color="grey-lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ImageGallery",
  props:{
    images:Array
  }
}
</script>

<style scoped>

</style>