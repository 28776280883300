import AuthService from '@/services/auth.service';
// Pinia Store
import { defineStore } from 'pinia'
import ApiService from '@/services/api.service'
import {checkIdentificationStatus} from "@/services/identification.service";
import {useNotificationStore} from "@/stores/notification.module";
import NotificationElephant from "@/models/notification";


const user = JSON.parse(localStorage.getItem('user'));



const state = () => user
    ? { status: { loggedIn: true,identified:false  }, user}
    : { status: { loggedIn: false,identified:false }, user: null };
export const useAuthUserStore = defineStore('authUser',{
    state: state,



// getters
getters: {
isAdmin(){
    if(this.user && this.user.roles && this.user.roles.includes('ROLE_ADMIN')) return true;
    else return false;
}
},

// actions
actions:{
    login(user) {
        return AuthService.login(user).then(
            user => {
                this.loginSuccess(user);
                return Promise.resolve(user);
            },
            error => {
                console.log("Message",error.response);
                console.log("AuthError",error.response.data);
                this.loginFailure();
                return Promise.reject(error);
            }
        );
    },
    logout() {
        AuthService.logout();
        this.status.loggedIn = false;
        this.user = null;
    },
    register(user) {
        return AuthService.register(user).then(
            response => {
                this.registerSuccess();
                if(response) return Promise.resolve(response.data);
                else return null;
            },
            error => {
                console.log("Error",error);
                this.registerFailure();
                return Promise.reject(error);
            }
        );
    },
    loginSuccess(user) {
        this.status.loggedIn = true;
        this.user = user;
    },
    loginFailure() {
        this.status.loggedIn = false;
        this.user = null;
    },
    registerSuccess() {
        const notificationStore = useNotificationStore();
        notificationStore.push(new NotificationElephant({title:"Registrierung erfolgreich.",message:null,color:'SUCCESS'}))
        this.status.loggedIn = false;
    },
    registerFailure() {
        this.status.loggedIn = false;
    },
    updateUser(){
        if(this.user==null) return null;
        ApiService.post("user/update",this.user).then(response => {
            this.updateLoggedInUser(response.data);
        });
    },
    verifyEmail(){
      this.user.emailVerified=true;
      this.updateLoggedInUser(this.user);
    },
    updateLoggedInUser(newUser){
        let user =JSON.parse(localStorage.getItem("user"));
        let rolesTmp=user.roles;
        let merged = {...user, ...newUser};

        merged.roles=rolesTmp;
        localStorage.setItem('user', JSON.stringify(merged));

        this.user=merged;
    },
    async checkIdentificationStatus() {
        this.status.identified = await checkIdentificationStatus();
    }
}
});