<template>
    <div>
      <TimerComponent :seconds="timer.seconds" :minutes="timer.minutes" :hours="timer.hours" :days="timer.days" />
    </div>
</template>

<script>
import { useTimer } from 'vue-timer-hook';
import TimerComponent from "@/components/countdown/TimerComponent";
export default{
  name: "CountdownTimer",
  components: {TimerComponent},
  props:{
    expiryTimestamp: {
      type: Number,
      required: true
    }
  },
  data(){
    return {
      timer: null
    }
  },
  created() {
    this.timer = useTimer(this.expiryTimestamp)

  },
  methods:{
    reload() {
      // Restarts to 10 minutes timer
      const time = new Date();
      time.setSeconds(time.getSeconds() + 600);
      this.timer.restart(time);
    }
  }
}


</script>