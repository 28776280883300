<template>

  <div style="box-sizing: border-box;position:relative" :class="[reward.rarity]" class="card pa-5">
    <!--<span style="position:absolute;left:0;top:0;">{{index}}</span>-->
    <div style="min-height: 50px">
    <v-img width="50" style="margin-left:10px;margin-top:-10px" :src="require('@/assets/icons/'+icon)"/>
    </div>
      <div style="">{{ reward.display.replace(' Tokens','') }}</div>

  </div>
</template>

<script>
export default {
  name: "CaseOpenerCard",
  props:{
    reward:Object,
    index:Number
  },
  computed:{
    icon() {
      switch (this.reward.type) {
        case 'GIVE_TOKENS':
          return 'GiveTokens.svg'
        case 'GIVE_SLOT':
          return 'Give_Slot.svg'
        case 'GIVE_RERAFFLE':
          return 'GiveReraffle.svg'
        case 'GIVE_NOTHING':
          return 'Give_Nothing.svg'
        default:
          return 'GiveTokens.svg'
      }
    }
  }
}
</script>

<style scoped>

.card {
  display: inline-block;
  text-align: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
  width: 100px;
  height: 100px;
}

.UNCOMMON{
  background-color: #2F2F2F;
}
.COMMON{
  background-color: #304259;
}
.RARE{
  background-color: #A96F9D;
}
.LEGENDARY{
  background-color: #CCA876;
}

</style>