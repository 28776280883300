<template>
<v-slide-group :disabled="disabledP" v-model="typeOfObjectReactive" class="" selected-class="bg-accent">
  <v-slide-group-item :disabled="disabled" v-for="type of objectTypes" :key="type" :value="type" v-slot="{selectedClass, toggle }">

      <v-sheet style="cursor: pointer" class="d-flex flex-wrap justify-center align-content-center text-center mr-4" rounded @click="toggle" :class="[ selectedClass]" height="100" color="secondary">
        <v-icon v-if="type==='TYPE_BAUERNHOF'" icon="mdi-home-silo-outline"></v-icon>
        <v-icon v-else-if="type==='TYPE_MEHRFAMILIENHAUS'" icon="mdi-home-switch-outline"></v-icon>
        <v-icon v-else-if="type==='TYPE_WOHNUNG'" icon="mdi-home-city-outline"></v-icon>
        <v-icon v-else-if="type==='TYPE_LAGERHALLE'" icon="mdi-warehouse"></v-icon>
        <v-icon v-else-if="type==='TYPE_GEWERBEFLAECHE'" icon="mdi-store-outline"></v-icon>
        <v-icon v-else icon="mdi-home-outline"></v-icon>
        <small style="width:100%" class="text-capitalize" v-html="type.replace('TYPE_','')"></small>
      </v-sheet>
  </v-slide-group-item>
  </v-slide-group>
</template>

<script>

import 'vue3-carousel/dist/carousel.css'
import {useObjectStore} from "@/stores/objects.module";
import getAllObjectTypes from "@/services/enum.service";
export default {
  name: "TypeOfObjectSelection",
  components:{
  },
  setup() {
    const objectStore = useObjectStore();



    return {
      objectStore
    }
  },
  methods:{
    async getObjectTypes() {
      this.objectTypes = await getAllObjectTypes();
    }
  },
  computed:{
    typeOfObjectReactive:{
      get(){
        return this.typeOfObject
      },set(value){
        this.$emit("update",value);
      }
    }
  },
  data(){
    return{
      objectTypes:[]
    }
  },
  props:{
    typeOfObject:String,
    disabledP:Boolean
},
  created() {
    this.getObjectTypes();
  }
}
</script>

<style scoped>

</style>