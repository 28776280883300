import ApiService from "@/services/api.service";
import {convertBase64} from "@/services/image.service";
import {useCoursesStore} from "@/stores/courses.module";

export async function getAllCoursesBySection(sectionId){
    const response = await ApiService.get("courses/find/all/section/"+sectionId);
    return response.data;
}
export async function getAllSections(){
    const response = await ApiService.get("courses/find/all/sections");
    return response.data;
}
export async function getAllSectionsPopulated(){
    const response = await ApiService.get("courses/find/all/sections/populated");
    return response.data;
}
export async function deleteCourse(courseId){
    const response = await ApiService.get("courses/course/remove/"+courseId);
    return response.data;
}
export async function createSection(section){
    const response = await ApiService.post("courses/section/create",section);
    return response.data;
}
export async function updateSection(section){
    const response = await ApiService.post("courses/section/update",section);
    return response.data;
}
export async function findMyLatestCourse(){
    const response = await ApiService.get("courses/user/find/latest");
    return response.data;
}
export async function deleteSection(sectionId){
    const response = await ApiService.get("courses/section/delete/"+sectionId).catch(function (error) {
        if (error.response) {
            if (error.response.status === 500) {
                console.log("Cannoit delete");
                 return null;
                }
        }
    });
    return response.data;
}
export async function changePublishStatusCourse(courseId,state){
    const response = await ApiService.get("courses/course/"+courseId+"/set/"+state);
    return response.data;
}
export async function getSectionById(id){
    const response = await ApiService.get("courses/section/get/"+id+"/populated");
    return response.data;
}
export async function getSectionByIdUnpopulated(id){
    const response = await ApiService.get("courses/section/get/"+id);
    return response.data;
}
export async function createCourse(createCourseRequest){
    const response = await ApiService.post("courses/create",createCourseRequest);
    return response.data;
}
export async function addVideoUrl(courseId,url){
    const response = await ApiService.post("courses/video/url/save/"+courseId,{string:url});
    return response.data;
}
export async function uploadVideoToCourse(courseId,request){
    //request.file = await convertBase64(request.file);
    let formData = new FormData();
    formData.append("file",request.file);
    console.log("File",request.file);
    const response = await ApiService.post("courses/videofile/add/"+courseId,formData);
    return response.data;
}
export async function uploadVideoToCourseOld(courseId,request){
    request.file = await convertBase64(request.file);
    const response = await ApiService.post("courses/video/add/"+courseId,request);
    return response.data;
}

export async function createQuestionForCourse(request){
    const response = await ApiService.post("courses/question/add",request);
    if(response.status===200) return response.data;
    else {
        console.log("Error");
        return null
    }
}
export async function getQuestionToEdit(questionId){
    const response = await ApiService.get("courses/question/"+questionId+"/edit/get");
    return response.data;
}
export async function editQuestion(questionId,request){
    console.log("Request",request);
    const response = await ApiService.post("courses/question/"+questionId+"/edit",request);
    return response.data;
}
export async function editDescription(courseId,request){
    const response = await ApiService.post("courses/course/description/edit/"+courseId,request);
    console.log(response);
    return response.data;
}

//User Courses
export async function findAllUsersCoursesBySection(sectionId){
    const response = await ApiService.get("courses/user/find/section/"+sectionId);
    return response.data
}
export async function startCourse(sectionId,courseId){
    const response = await ApiService.get("courses/"+sectionId+"/"+courseId+"/start");
    return response.data
}
export async function unlockSection(sectionId){
    const response = await ApiService.get("courses/user/section/"+sectionId+"/unlock");
    if(response.data===true){
        const coursesStore = useCoursesStore();
        coursesStore.getAllSections();
    }
    return response.data;
}
export async function validateTest(request){
    const response = await ApiService.post("courses/test/validate",request);
    return response.data
}