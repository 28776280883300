<template>
<router-view/>
</template>

<script>

export default {
  name: "StatisticsWrapper",

}
</script>

<style scoped>

</style>