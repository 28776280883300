<template>
  <v-sheet rounded class="d-flex align-center justify-center align-content-center rounded-xl" v-if="message" :width="[desktop ? '100%': '92%']" :height="[desktop ? '100%':'70%']" style="position:absolute; overflow: visible; z-index:2; top:50%; left:50%;transform:translate(-50%,-50%)" color="blue">
    <v-btn :loading="loading" flat size="small" variant="text" style="position:absolute; top:5px;left:5px" class="pa-2" icon
           @click="getNew"><v-icon>mdi-refresh</v-icon></v-btn>

    <v-row>

    <v-col class="py-0 d-flex justify-left pl-7  flex-wrap align-content-center" cols="7">
      <div class="mb-2 text-left" style="width:100%;font-size: 0.9rem;" v-html="message.content"></div>
      <v-btn @click="$router.push(message.link)" class="" size="small" color="accent" v-html="message.buttonText"></v-btn>
    </v-col>
    <img v-if="message.image" style="position: absolute; bottom:0;right:15px;z-index:-1; height:110%;width:auto"  :src="'data:image/webp;base64,'+message.image"/>
    <v-col v-else cols="5">
    <img style="width:80%" :src="require('@/assets/app_icon.svg')"/>
    </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import {getRandomDashboardMessage} from "@/services/dashboardMessage.service";

export default {
  name: "DashboardMessage",
  data(){
    return{
    message:null,
      loading:false
    }
  },
  props:{
    desktop:Boolean
  },
  async created() {
    this.message = await getRandomDashboardMessage();
  },
  methods:{
    async getNew() {
      this.loading=true;
      this.message = await getRandomDashboardMessage();
      this.loading=false;
    }
  },
  computed:{
    dynamicCols(){
      if(!this.message) return 7;
      else if(this.message.image) return 7;
      else return 12;
    }
  }
}
</script>

<style scoped>

</style>