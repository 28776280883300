// Pinia Store
import { defineStore } from 'pinia'
import {
    calculatetotalEarnedPointsEarnedToLevel,
    calculateXPNeededForNextLevel,
    getCurrentLevel, getProgressWithoutApiService
} from "@/services/progress.service";
import ApiService from "@/services/api.service";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {useRewardStore} from "@/stores/reward.module";



const state = () => ({
    progress:{
        id:null,
        level:0,
        pointsInCurrentLevel:0,
        neededPointsToNext:0,
        total_points:0
    },
    newRewards:[]
});
export const useUserProgressStore = defineStore('progressUser',{
    state: state,



// getters
    getters: {
        currentLevel(state){
            return getCurrentLevel(state.progress.total_points);
        },
        pointsInCurrentLevel(state){
            //console.log(calculatetotalEarnedPointsEarnedToLevel(this.currentLevel+1));
            return state.progress.total_points-calculatetotalEarnedPointsEarnedToLevel(this.currentLevel);
        },
        pointsToNextLevel(){
          //return calculatetotalEarnedPointsEarnedToLevel(this.currentLevel+1)-calculatetotalEarnedPointsEarnedToLevel(this.currentLevel);
            return calculateXPNeededForNextLevel(this.currentLevel+1);
        }
    },

// actions
    actions:{
        //TODO THis is called in Backend but the Object that comes back is the same
        addProgress(amount){
            ApiService.get("user/progress/add/"+amount).then(response => {
                const rewardStore = useRewardStore();
                this.newRewards=response.data.newRewards;
                this.getProgress();
                rewardStore.getMyRewards();

            });
        },
        removeNewReward(reward){
            this.newRewards=this.newRewards.splice(this.newRewards.indexOf(x=> x.id === reward.id),1);

        },
        getProgress(){
            const authStore = useAuthUserStore();
            ApiService.get("user/progress/get/"+authStore.user.id).then(response => {
                if(!response) this.progress=0;
                this.progress=response.data;
            });
        },
        getProgressWithoutApiService(){
            const authStore= useAuthUserStore();
            getProgressWithoutApiService(authStore.user.id).then(response => {
                if(response) this.progress=response;
            })
        }
    }
});