<template>
<v-container>
  <v-form>
    <v-text-field v-model="request.question" label="Question" prepend-icon="mdi-help"/>
    <p>Antworten</p>
    <v-text-field v-for="(answer,$index) of request.answers" :key="$index" v-model="request.answers[$index].answer" :label="'Antwort '+($index+1)">
      <template #append>
        <v-checkbox hide-details v-model="request.answers[$index].correct"></v-checkbox>
      </template>
    </v-text-field>
    <v-btn variant="text" @click="$router.back()">Zurück</v-btn><v-btn color="success" @click="editQuestion" :loading="saving">Speichern</v-btn>
  </v-form>
</v-container>
</template>

<script>
import Answer from "@/models/test/answer";
import {editQuestion, getQuestionToEdit} from "@/services/courses.service";

export default {
  name: "EditQuestion",
  props:{
    questionId:String,
    courseId:String,
    sectionId:String,
  },
  data(){
    return{
      saving:false,
      request:{
        question:null,
        answers:[new Answer(),new Answer(), new Answer()],
        courseId:null
      }
      }
  },
  created() {
    this.getQuestion();
  },
  methods:{
    async editQuestion(){
      this.saving=true;
      this.request.courseId=this.courseId;
      this.request = await editQuestion(this.questionId,this.request);
      this.saving=false;
      },
    async getQuestion() {
      this.request = await getQuestionToEdit(this.questionId);
    }
  }
}
</script>

<style scoped>

</style>