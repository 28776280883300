<template>
<v-dialog v-click-outside="claimLater" v-model="show">
  <v-card>

    <v-card>
      <v-toolbar :title="rewardObject?.title"></v-toolbar>
      <v-card-text>
        <div class="py-5 d-flex justify-center flex-wrap">
          <RewardIconByType :size="100" :type="rewardObject?.rewardType"/>
          <span class="text-h4 mt-5" style="width:100%;text-align: center;" ><span v-if="!rewardObject?.daily" v-html="rewardObject?.display"></span><span v-else>Daily Reward</span></span>
        </div>
      </v-card-text>

      <v-card-actions >
        <v-spacer/>
        <v-btn @click="claimLater">später</v-btn>
        <v-btn color="accent" @click="claim" v-if="!reward.claimed">einsammeln</v-btn>
        <v-btn v-else disabled>Bereits eingesammelt</v-btn>
      </v-card-actions>
    </v-card>

  </v-card>
</v-dialog>
</template>

<script>
import {useUserProgressStore} from "@/stores/progress.module";
import {claimReward, getRewardByLevel} from "@/services/reward.service";
import {useRewardStore} from "@/stores/reward.module";
import {useWalletStore} from "@/stores/wallet.module";
import RewardIconByType from "@/components/progress/RewardIconByType";

export default {
  name: "NewRewardPopup",
  components: {RewardIconByType},
  setup(){
    const progressStore = useUserProgressStore();
    const rewardStore =useRewardStore();
    const walletStore = useWalletStore();
    return {
      progressStore,
      rewardStore,
      walletStore
    }
  },
  data(){
    return{
      show:true,
      rewardObject:null
    }
  },
  props:{
    reward:Object
  },

  created() {
    this.getRewardObject();
    navigator.vibrate(200);
  },
  methods:{
    getRewardObject(){
      let self=this;
      console.log(parseInt(self.reward.rewardId.slice(-1)));
      getRewardByLevel(parseInt(self.reward.rewardId.slice(-1))).then(response => {
        self.rewardObject=response;
      })
    },
    claimLater(){
      if(!this.reward.daily) this.progressStore.removeNewReward(this.reward);
      this.show=false;
    },

      claim(){
        let self=this;
        claimReward(this.reward).then(() =>{
          //TODO Display Popup wih Hey you claimed the reward
          self.rewardStore.getMyRewards();
          self.walletStore.getMyWallet();
          if(!this.reward.daily) self.progressStore.removeNewReward(self.reward)
          self.show=false;
        });
      }
  }
}
</script>

<style scoped>

</style>