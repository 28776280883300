<template>
  <v-list-item :disabled="disabled" align="left" class="bg-secondary py-3 my-2" rounded :title="title">
    <template #subtitle>
      <span :class="messageColor" v-html="message"></span>
    </template>
    <template v-slot:prepend>
    <v-avatar color="primary"><v-progress-circular v-if="statusIcon==='mdi-load'" indeterminate></v-progress-circular><v-icon v-else :class="{'text-error':status==='error','text-success':status==='success'}" :icon="statusIcon"></v-icon></v-avatar>
    </template>
  </v-list-item>
</template>

<script>
export default {
  name: "StepListItem",
  props:{
    title:String,
    status:String,
    disabled:Boolean,
    message:String,
    messageColor:String
  },
  computed:{
    statusIcon(){
      if(this.status==='active-user-interaction'){
        return 'mdi-account-clock';
        }
      else if(this.status==='loading')return 'mdi-load';
    else if(this.status==='waiting') return 'mdi-sleep';
      else if(this.status==='error') return 'mdi-exclamation';
      else if(this.status==='success') return 'mdi-check';
      else return 'mdi-sleep';
    }
  }
}
</script>

<style scoped>

</style>