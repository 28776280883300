<template>
<v-sheet width="100%" @click="toggleCourseView" style="position:relative" class="py-5 my-3 my-md-0 px-5 rounded-xl" color="blue">
<div class="d-flex justify-space-between">
  <div class="text-left" :class="{'text-disabled':disabled}">
    <small v-if="section" class="text-accent">{{ section.title }}</small>
  </div>
  <div>
    <v-chip size="small" :color="stateColor">{{stateText}}</v-chip>
  </div>
</div>
  <div :class="{'text-disabled':disabled}" class="d-flex">
    <div style="width:80%" class="d-flex justify-start">
      <span class="text-left" style="font-size:1.3rem; font-weight: bold; letter-spacing: 2.5px">{{course.course.title}}</span>
    </div>
    <div style="width:20%" class="flex-grow-1 text-center">
      <div  style="font-size:6rem;
    position: relative;
    text-align: center;
    font-size: 6rem;
    opacity: 0.6;
    line-height: 70px;
    bottom: -21px;">{{course.course.chapter}}</div>
    </div>
  </div>

</v-sheet>
  <v-dialog eager fullscreen v-model="showCourse">
      <CourseSite @toggleDialog="toggleCourseDialog" :section="section" :course="course">

      </CourseSite>

  </v-dialog>
</template>

<script>
import CourseSite from "@/components/courses/CourseSite";
export default {
  name: "CourseItem",
  components: {CourseSite},
  props:{
    section:Object,
    course:Object
  },
  data(){
    return{
    showCourse:false
    }
  },
  methods:{
    toggleCourseDialog(value){
      if(this.course.state==='LOCKED') return null;
      else this.showCourse=value;
    },
    toggleCourseView(){
      if(this.course.state==='LOCKED') return null;
      else this.showCourse=!this.showCourse;
    }
  },
  computed:{
    disabled(){
      return this.course.state==='LOCKED';
    },
    stateColor(){
      switch(this.course.state){
          case 'LOCKED': return 'grey';
          case 'NOT_STARTED': return 'success';
          case 'IN_PROGRESS': return 'success';
          case 'FINISHED': return 'accent';
          default: return 'grey'
    }
    },
    stateText(){
      switch(this.course.state){
        case 'LOCKED': return 'nicht freigeschaltet';
        case 'NOT_STARTED': return 'nicht gestartet';
        case 'IN_PROGRESS': return 'läuft';
        case 'FINISHED': return 'abgeschlossen';
        default: return 'gesperrt';
      }
    }
  }
}
</script>

<style scoped>

</style>