<template>
  <EstateObjectStatisticList state="Pending" color="bg-secondary" :pending-objects="pendingObjects"/>
  <EstateObjectStatisticList state="Sold" color="bg-success" :pending-objects="soldObjects"/>
  <EstateObjectStatisticList state="Cancelled" color="bg-error" :pending-objects="canceledObjects"/>
  </template>
<script>
import EstateObjectStatisticList from "@/components/admin/statistics/EstateObjectStatisticList";

export default {
  name: 'EstateObjectStatistic',
  components: {EstateObjectStatisticList},
  props: {
    canceledObjects: {},
    pendingObjects: {},
    soldObjects: {}
  }
}
</script>
