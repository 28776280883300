import Address from "@/models/address";

class ObjectOwner {
    constructor() {
        this.id=null;
        this.fullName=null;
        this.email=null;
        this.telephone=null;
    }
}

export default class EstateObject{
    constructor(createdBy) {
        this.id = null;
        this.objectType=null;
        this.parkingFacilities=null;
        this.landArea=0;
        this.livingArea=0;
        this.objectOwner=new ObjectOwner();
        this.sellType=null;
        this.images=[];
        this.createdBy=createdBy;
        this.address=new Address();
    }
}