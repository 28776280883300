<template>
<v-list-item lines="3" :title="'Auszahlung vom '+timestamp">
  <template #subtitle>
    <p> <span v-text="-payout.transaction.coinsDelta"></span> Coins</p>
    <p v-if="payout.status==='PENDING'">Wir überprüfen & bereiten deine Auszahlung vor.</p>
    <p v-else-if="payout.status==='FAILED'">Es gab leider einen Fehler. Bitte melde dich bei uns.</p>
    <p v-else>Dein Geld wurde überwiesen.</p>
  </template>
<template #prepend>
  <v-avatar>
    <v-icon v-if="payout.status==='PENDING'">mdi-receipt-clock-outline</v-icon>
    <v-icon v-else-if="payout.status==='FAILED'">mdi-alert-circle-outline</v-icon>
    <v-icon v-else color="green">mdi-check-bold</v-icon>
  </v-avatar>
</template>
</v-list-item>
</template>

<script>
export default {
  name: "PayoutItem",
  props:{
    payout:Object
  },
  computed:{
    timestamp(){
      let moment = require('moment');
      moment.locale('de');
      return moment(this.payout.transaction.timestamp).format('LL');
    }
  }
}
</script>

<style scoped>

</style>