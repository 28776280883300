<template>
<ConfirmToken :token="token"/>
</template>

<script>
import ConfirmToken from "@/components/auth/ConfirmToken";
export default {
  name: "ConfirmationTokenView",
  components: {ConfirmToken},
  props:{
    token:String
  }
}
</script>

<style scoped>

</style>