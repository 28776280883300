import ApiService from '@/services/api.service';

export async function getRequirements(objectId) {
    const response = await ApiService.get("requirements/get/"+objectId);
    return response.data;
}
export async function getVerificationState(objectId){
    const response = await ApiService.get("requirements/verified/get/"+objectId);
    return response.data;
}
