import {createRouter, createWebHistory} from 'vue-router'
import EditAddressView from "@/views/EditAddressView";
import VerificationList from "@/components/admin/VerificationList";
import AdminOverview from "@/components/admin/AdminOverview";
import EditObjectAdmin from "@/components/admin/object/EditObjectAdmin";
import AdminWrapper from "@/components/admin/AdminWrapper";
import AdminCourseSections from "@/components/admin/courses/AdminCourseSections";
import CreateSection from "@/components/admin/courses/section/CreateSection";
import AdminCourses from "@/components/admin/courses/AdminCourses";
import CreateCourse from "@/components/admin/courses/CreateCourse";
import AdminCourseQuestions from "@/components/admin/courses/test/AdminCourseQuestions";
import EditSection from "@/components/admin/courses/section/EditSection";
import CreateQuestion from "@/components/admin/courses/test/CreateQuestion";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import PayoutWrapper from "@/components/admin/payout/PayoutWrapper";
import EditQuestion from "@/components/admin/courses/test/EditQuestion";
import ConfirmationTokenView from "@/views/ConfirmationTokenView";
import HomeWrapper from "@/views/HomeWrapper";
import StatisticsWrapper from "@/components/admin/statistics/StatisticsWrapper";
import StatisticDashboard from "@/components/admin/statistics/StatisticDashboard";



function guardMyRoute(to,from,next){
  const authUserStore = useAuthUserStore();
  let isAuthenticated = authUserStore.status.loggedIn;
  if (to.name !== 'Login' && !isAuthenticated) {
    next('/login');
  }
  else{
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter:guardMyRoute,
    component: HomeWrapper,
    meta: { appBarBackButton: false }
  },
  {
    path: '/about',
    name: 'about',

    meta: { appBarBackButton: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },{
    path: '/change/password',
    name: 'Change Password',
    meta: { appBarBackButton: true },
    component: () => import('../views/ChangePasswordView')
  },
  {
    path:'/daily',
    name:'Daily Reward',
    component:() => import('@/views/DailyRewardPage')
  },

  {
    path: '/login',
    props:true,
    meta: { appBarBackButton: false },
    name: 'Login',
    component: () => import('@/views/LoginWrapper')
  },
  {
    path: '/logout',

    meta: { appBarBackButton: false },
    name: 'Logout',
    component: () => import('@/components/auth/Logout')
  },
  {
    path: '/fortschritt',
    name: 'Fortschritt',

    meta: { appBarBackButton: false },
    component: () => import('@/views/ProgressView')
  },

  {
    path: '/wallet',
    name: 'Guthaben',

    meta: { appBarBackButton: false },
    component: () => import('@/views/WalletView')
  },
  {
    path: '/ranking',
    name: 'Ranking',

    meta: { appBarBackButton: false },
    component: () => import('@/views/RankingView')
  },
  {
    path: '/profil',
    name: 'Profil',

    meta: { appBarBackButton: false },
    component: () => import('@/views/UserOwnProfileView')
  },
  {
    path: '/profil/daten',
    name: 'Accountdaten',

    meta: { appBarBackButton: true },
    component: () => import('@/views/EditAccountDataView')
  },
  {
    path: '/profil/addressen',
    name: 'Deine Addressen',

    meta: { appBarBackButton: true },
    component: () => import('@/views/ProfileAddressView')
  },
  {
    path: '/addresse/editieren',
    name: 'Addresse bearbeiten',

    meta: { appBarBackButton: true },
    component: () => import('@/views/EditAddressView')
  },
  {
    path: '/profil/teilen',
    name: 'Profil teilen',

    meta: { appBarBackButton: true },
    component: () => import('@/views/UserQRCodeView')
  },
  {
    //TODO mit :id dynamisch anzeigen
    path: '/immobilie/:objectId',
    name: 'Immobilien Fortschritt',
    props:true,
    meta: { appBarBackButton: false },
    component: () => import('@/views/ObjectView')
  },
  {
    path: '/immobilie/daten',
    name: 'Daten hinzufügen',
    children: [
      {
        path: 'adresse',
        component: EditAddressView,
      },
        ],

    meta: { appBarBackButton: true },
    component: () => import('@/views/EditObjectData')
  },
  {
    path:'/immobilie/erstellen',
    name:'Objekt anlegen',
    component: () => import('@/views/CreateObjectView')

  },
  {
    path:'/achievements',
    name:'Achievements',
    component: () => import('@/views/AchievementView')

  },
  {
    path:'/weiterbildung',
    name:'Weiterbildungszentrale',
    component: () => import('@/views/SectionView')
  },
  {
    path:'/user/bank-details',
    name:'Bank Details',
    component: () => import('@/views/BankDetailsView')
  },
  {
    path:'/weiterbildung/sektion/:sectionId',
    name:'Kurse',
    meta: { appBarBackButton: true },
    props:true,
    component: () => import('@/views/CoursesView')
  },
  {
    path: '/confirm/token/:token',
    component: ConfirmationTokenView,
    props:true,
    name: 'Confirm Token',
  },
  {
    path: '/admin',
    meta: {admin:true},
    name: 'Adminbereich',
    children: [
      {
        path:'statistic',
        component: StatisticsWrapper,
        name:'StatistikWrapper',
        children:[
          {
            path:'',
            component: StatisticDashboard,
            name:'Statistic Dashboard',
          },
        ]
      },
      {
        path: 'objekte',
        component: AdminOverview,
        name: 'Objekte',
        meta: { appBarBackButton: true },
      },
      {
        path: 'weiterbildung/section',
        component: AdminCourseSections,
        name: 'Sektionen',
        meta: {appBarBackButton: true},
      },
      {
        path: 'payout',
        component: PayoutWrapper,
        name: 'Auszahlungen',
        meta: {appBarBackButton: true},
      },
      {
            path:'weiterbildung/section/create',
            component: CreateSection,
            name: 'Erstelle Sektion',
            meta: { appBarBackButton: true },
      },{
            path:'weiterbildung/section/:sectionId/edit',
            component: EditSection,
            name: 'Aktualisiere Sektion',
            props:true,
            meta: { appBarBackButton: true },
      },
      {
            path: 'weiterbildung/section/:sectionId/course',
            component: AdminCourses,
            props:true,
            name: 'Sektion',
            meta: {appBarBackButton: true},
      },
      {
            path: 'weiterbildung/section/:sectionId/course/:courseId/question',
            component: AdminCourseQuestions,
            props:true,
            name: 'Fragen',
            meta: {appBarBackButton: true},
      },
      {
            path: 'weiterbildung/section/:sectionId/course/:courseId/question/:questionId/edit',
            component: EditQuestion,
            props:true,
            name: 'Frage bearbeiten',
            meta: {appBarBackButton: true},
      },
      {
        path: 'weiterbildung/section/:sectionId/course/create',
        component: CreateCourse,
        props:true,
        name: 'Erstelle Kurs',
        meta: {appBarBackButton: true},
      },
      {
        path: 'weiterbildung/section/:sectionId/course/:courseId/question/create',
        component: CreateQuestion,
        props:true,
        name: 'Erstelle Frage',
        meta: {appBarBackButton: true},
      },
      {
        path: '',
        component: AdminWrapper,
        name: 'Überblick',
      },
      {
        path: 'verifizierungen',
        component: VerificationList,
        name: 'Verifizierungen',
        meta: { appBarBackButton: true },
      },
      {
        path: 'objekt/:objectId',
        props:true,
        component: EditObjectAdmin,
        name: 'Objekt bearbeiten',
        meta: { appBarBackButton: true },
      },
    ],

    component: () => import('@/views/AdminView')
  },
  {
    path: '/u',
    name: 'Anonym',
    children: [
      {
        path: 'reset/password/u/:userId/t/:token',
        name: 'Password Reset',
        props:true,
        meta: { appBarBackButton: false },
        component: () => import('../views/ForgotPasswordView')
      },
      {
        path: 'reset/password/request',
        name: 'Request Password Reset',
        meta: { appBarBackButton: false },
        component: () => import('../views/RequestPasswordForgotView')
      },
      {
        path:'page/:hash',
        props:true,
        name:'Seite',
        component:() => import('@/views/UserPageView')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),//createWebHashHistory(),//,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.admin) {
    const authUserStore = useAuthUserStore();
    if(!authUserStore.user) return false;
    const roles = authUserStore.user.roles;
    if(roles.includes("ROLE_ADMIN")) next();
  }else next();
})

export default router
