<template>
  <UserAvatar :loading="!currentUser" :size="size" :user="currentUser"/>
</template>

<script>
import {useAuthUserStore} from "@/stores/modules/auth.module";
import UserAvatar from "@/components/user/UserAvatar";

export default {
  name: "CurrentUserAvatar",
  components: {UserAvatar},
  setup(){
    const authStore = useAuthUserStore();

    return{
      authStore
    }
  },
  computed:{
    currentUser(){
      return this.authStore.user;
    }
  },
  props:{
    size:{
      default:50,
      type:Number
    }
  }
}
</script>

<style scoped>

</style>