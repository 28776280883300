<template>
  <v-dialog v-model="show" fullscreen>
    <template #activator="{props}">
    <v-list-item v-bind="props" :title="objectName" :subtitle="status">

    </v-list-item>
    </template>
    <v-card>
      <v-toolbar><v-spacer/><v-btn icon @click="show=false" variant="text"><v-icon icon="mdi-close"></v-icon></v-btn></v-toolbar>
      <v-card-text>
        <v-list-item  :subtitle="status">
          <template #title>
            <v-tooltip location="bottom">
              <template #activator="{props}">
                <span v-bind="props" v-html="objectName"></span>
              </template>
              <span v-html="objectName"></span>
            </v-tooltip>

          </template>
          <template #append>
            <v-btn icon @click="verify" color="success" variant="text"><v-icon icon="mdi-check"></v-icon></v-btn>
            <v-dialog persistent v-model="declineDialog">
              <template #activator="{props}">
                <v-btn v-bind="props" icon color="error" variant="text"><v-icon icon="mdi-close"></v-icon></v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <p>Warum ist die Verifizierung fehlgeschlagen?</p>
                  <v-textarea class="mt-2" placeholder="Grund hier..." v-model="declineMessage" label="Grund für Fehlschlag">

                  </v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-btn variant="text" @click="declineDialog=false">Abbrechen</v-btn><v-spacer/><v-btn @click="decline" color="warning">Absenden</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

        </v-list-item>
        <v-btn @click="download" class="my-5" text><v-icon>mdi-download</v-icon> Einverständniserklärung des Eigentümers</v-btn>
        <ObjectInfo :object="estateObject"/>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {getConsentForm, getEstateObject, getObjectName} from "@/services/object.service";
import ObjectInfo from "@/components/admin/object/ObjectInfo";
import {acceptVerification, declineVerification} from "@/services/admin.service";
import {useAdminStore} from "@/stores/admin.module";
import {downloadFileFromBase64} from "@/services/file.service";

export default {
  name: "VerificationItem",
  components: {ObjectInfo},
  setup(){
    const adminStore = useAdminStore();
    return {
      adminStore
    }
  },
  props:{
    verification:Object
  },
  data(){
    return{
      show:false,
      estateObject:null,
      declineMessage:"",
      declineDialog:false,
      consent:null
    }
  },
  created() {
    this.getEstateObject();
    this.getConsentForm();
  },
  methods:{
    download(){
      downloadFileFromBase64(this.consent.contract)
    },
    async getConsentForm(){
      this.consent= await getConsentForm(this.verification.objectId);
    },
    async getEstateObject() {
      this.estateObject = await getEstateObject(this.verification.objectId);
    },
    async verify() {
      await acceptVerification(this.estateObject.id);
      this.adminStore.getPendingVerifications();
    },
    async decline(){
      await declineVerification(this.estateObject.id,this.declineMessage);
      this.declineDialog=false;
      this.adminStore.getPendingVerifications();
    }
  },
  computed:{
    objectName(){
      if(this.estateObject) return getObjectName(this.estateObject);
      else return "";
    },
    status(){
      switch (this.verification.state) {
        case 'ANGERUFEN':
          return 'bereits angerufen';
        case 'NICHT_DURCHGEKOMMEN':
          return 'nicht durchgekommen'
        case 'WARTE_AUF_RUECKMELDUNG':
          return 'warte auf Rückmeldung';
        case 'VERIFIZIERUNG_FEHLGESCHLAGEN':
          return 'Verifizierung fehlgeschlagen';
        case 'VERIFIZIERUNG_LÄUFT':
          return 'Verifizierung ausstehend';

        default:
          return 'Verifizierung ausstehend';
      }
    }
  }
}
</script>

<style scoped>

</style>