<template>
  <h3>Adminbereich</h3>
<v-list style="background-color: transparent" align="left">
  <v-list-item class="my-2" href="admin/objekte" title="Objekte" append-icon="mdi-chevron-right" subtitle="Status aller Objekte">

  </v-list-item>
  <v-list-item class="my-2" href="admin/weiterbildung/section" append-icon="mdi-chevron-right" title="Weiterbildungen" subtitle="Weiterbildungen hinzufügen und bearbeiten">

  </v-list-item>
  <v-list-item class="my-2" href="admin/payout" append-icon="mdi-chevron-right" title="Auszahlungen" subtitle="Alle Auszahlungen"></v-list-item>
</v-list>
</template>

<script>
export default {
  name: "AdminWrapper"
}
</script>

<style scoped>

</style>