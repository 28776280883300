<template>
  <v-form :disabled="disabled" class="my-5" @submit.prevent="sendTest">
    <div class="my-5" v-for="(question,$questionNumber) of course.course.test.questions" :key="question.id">
      <p :ref="'Question'+$questionNumber" class="text-left"><v-chip size="small"><span v-text="$questionNumber+1"></span></v-chip> <span v-text="question.question"></span></p>
      <v-radio-group v-model="testAnswers[$questionNumber]">
        <v-radio class="my-2" v-for="(answer,$index) of question.answers" :key="$index" :label="answer" :value="$index"></v-radio>
      </v-radio-group>
    </div>
    <v-btn :disabled="disabled" color="accent" type="submit" block>Abschicken</v-btn>
    <v-alert closable class="my-5" v-if="wrongAnswers" type="error" title="Leider nicht geschafft." style="font-size: 1rem;"></v-alert>
  </v-form>
  <v-dialog v-model="showSuccess">
    <v-card>
      <v-card-text>
        Du hast den Test erfolgreich abgeschlossen &
        50 Token, Punkte & das nächste Kapitel freigeschaltet.
      </v-card-text>
      <v-card-actions>
        <v-btn color="accent" :href="'/weiterbildung/sektion/'+course.course.sectionId">danke</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {validateTest} from "@/services/courses.service";

export default {
  name: "CourseTest",
  props:{
    course:Object,
    admin:Boolean
  },
  data(){
    return{
      testAnswers:[],
      showSuccess:false,
      wrongAnswers:null
    }
  },
  computed:{
    disabled(){
      if(this.admin) return true;
      else if(this.course.state==='FINISHED') return true;
      else return false;
    }
  },
  methods:{
    async sendTest() {
      this.wrongAnswers=null;
      console.log("Test",this.testAnswers);
      let request = {
        sectionId: this.course.course.sectionId,
        courseId: this.course.course.id,
        answers: this.testAnswers
      }
      let wrongAnswers = await validateTest(request);
      if(wrongAnswers.length<=0){
        this.showSuccess=true;
      }
      else {
        this.wrongAnswers= wrongAnswers;
        console.log("Wreong answers",wrongAnswers);
        for(let answer of wrongAnswers){
          console.log("Wrong Answer",answer);
          console.log("Ref",this.$refs['Question'+answer][0]);
          this.$refs['Question'+answer][0].classList.add("wrong");
        }
      }
    }
  }
}
</script>

<style scoped>
.wrong{
  color:red;
}
</style>